import React from 'react';

const Orders = () => {
    return (
        <div className='text-center'>
            This is your orders.
        </div>
    );
};

export default Orders;