import React from 'react';
import HomeGallery from '../HomeGallery/HomeGallery';

const Gallery = () => {
    return (
        <div>
            <HomeGallery></HomeGallery>
        </div>
    );
};

export default Gallery;