import React from 'react';

const Estimation = () => {
    return (
        <div>
            This is our estimation
        </div>
    );
};

export default Estimation;