import { React, useState, useEffect } from "react";
import "./Form.css";

const Mainform = () => {
  
  const [services, setServices] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const [submissionStatus, setSubmissionStatus] = useState(null);


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phonenumber: "",
    service: "",
    thana: "",
    area: "",
    date: "",
    details: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    // Fetch the reviews data asynchronously
    fetch("/services.json")
      .then((response) => response.json())
      .then((data) => {
        setServices(data);
      });
  }, []);

  const handleSubmit = (e) => {
  //  e.preventDefault();

    fetch("/formreceive.php", {
      method: "POST",
      body: new FormData(e.target),
    })
      .then((response) => response.text())
      .then((data) => {
        setSubmissionStatus(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    }

  return (
    <div className="form-container-wrapper">
      <h2 className="text-3xl text-bold">
        আমাদের যেকোন সেবা নিতে নীচের ফর্মটি পূরণ করুন
      </h2>
      <div className="form-container">
      {submissionStatus === "success" ? (
          <div>
            <p>
              আপনার ফর্মটি গ্রহণ করা হয়েছে। খুব শীঘ্রই রাজসেবার একজন প্রতিনিধি
              আপনাকে কল করবে।
            </p>
          </div>
        ) : (
        <form onSubmit={handleSubmit} action="./formreceive.php" method="post">
          <div className="form-field">
            <label>নাম*</label>
            <input
              type="text"
              name="your-name"
              value={formData.value}
              onChange={handleInputChange}
              required
            >
              </input>
          </div>

          <div className="form-field">
            <label>ই-মেইল</label>
            <input
              type="email"
              name="your-email"
              value={formData.value}
              onChange={handleInputChange}
            >
              </input>
          </div>

          <div className="form-field">
            <label>ফোন নাম্বার* (১১ ডিজিট)</label>
            <input
              type="tel"
              name="phonenumber"
              value={formData.value}
              onChange={handleInputChange}
              required
              pattern="[0]{1}[1]{1}[3-9]{1}[0-9]{8}"
            >
              </input>
          </div>

          <div className="form-field">
            <label>সেবা পছন্দ করুন*</label>
            <select
              name="service"
              value={formData.value}
              onChange={handleInputChange}
              required
            >
              <option value="">--সার্ভিস সিলেক্ট করুন--</option>
              {services.map((service) => (
                <option key={service.id} value={service.servicename}>
                  {service.servicename}
                </option>
              ))}
            </select>
          </div>

          <div className="form-field">
            <label>থানা*</label>
            <select
              name="thana"
              required
              value={formData.value}
              onChange={handleInputChange}
            >
              <option value="">--থানা সিলেক্ট করুন--</option>
              <option value="মতিহার">মতিহার</option>
              <option value="রাজপাড়া">রাজপাড়া</option>
              <option value="বোয়ালিয়া">বোয়ালিয়া</option>
              <option value="শাহ-মখদুম">শাহ-মখদুম</option>
              <option value="চন্দ্রিমা">চন্দ্রিমা</option>
            </select>
          </div>

          {formData.thana === "মতিহার" && (
            <div className="form-field">
              <label>এরিয়া*</label>
              <select
                name="area"
                required
                value={formData.value}
                onChange={handleInputChange}
              >
                <option value="">--এরিয়া সিলেক্ট করুন--</option>
                <option value="কাজলা">কাজলা</option>
                <option value="বিনোদপুর">বিনোদপুর</option>
                <option value="ধরমপুর">ধরমপুর</option>
                <option value="রাজশাহী বিশ্ববিদ্যালয়">রাজশাহী বিশ্ববিদ্যালয়</option>
                <option value="রুয়েট">রুয়েট</option>
                <option value="তালাইমারি">তালাইমারি</option>
                <option value="চৌদ্দপাই">চৌদ্দপাই</option>
                <option value="বিহাস">বিহাস</option>
              </select>
            </div>
          )}

          {formData.thana === "রাজপাড়া" && (
            <div className="form-field">
              <label>এরিয়া*</label>
              <select
                name="area"
                required
                value={formData.value}
                onChange={handleInputChange}
              >
                <option value="">--এরিয়া সিলেক্ট করুন--</option>
                <option value="বহরমপুর">বহরমপুর</option>
                <option value="লক্ষীপুর">লক্ষীপুর</option>
                <option value="কেশবপুর">কেশবপুর</option>
                <option value="নবিনগর">নবিনগর</option>
                <option value="হেলেনাবাদ">হেলেনাবাদ</option>
                <option value="মহিষবাথান">মহিষবাথান</option>
                <option value="চন্ডীপুর">চন্ডীপুর</option>
                <option value="শ্রীরামপুর">শ্রীরামপুর</option>
                <option value="কাজীহাটা">কাজীহাটা</option>
                <option value="সিপাইপাড়া">সিপাইপাড়া</option>
                <option value="তেরখাদিয়া">তেরখাদিয়া</option>
                <option value="সপুরা">সপুরা</option>
              </select>
            </div>
          )}

          {formData.thana === "বোয়ালিয়া" && (
            <div className="form-field">
              <label>এরিয়া*</label>
              <select
                name="area"
                required
                value={formData.value}
                onChange={handleInputChange}
              >
                <option value="">--এরিয়া সিলেক্ট করুন--</option>
                <option value="দরগাপাড়া">দরগাপাড়া</option>
                <option value="হেতেম খাঁ">হেতেম খাঁ</option>
                <option value="কাদিরগঞ্জ">কাদিরগঞ্জ</option>
                <option value="সাহেব বাজার">সাহেব বাজার</option>
                <option value="সুলতানাবাদ">সুলতানাবাদ</option>
                <option value="সপুরা">সপুরা</option>
                <option value="বর্ণালী">বর্ণালী</option>
                <option value="উপশহর">উপশহর</option>
                <option value="বোয়ালিয়া">বোয়ালিয়া</option>
                <option value="শিরইল">শিরইল</option>
                <option value="সাগরপাড়া">সাগরপাড়া</option>
                <option value="রামপুর">রামপুর</option>
                <option value="রামচন্দ্রপুর">রামচন্দ্রপুর</option>
                <option value="সাধুর মোড়">সাধুর মোড়</option>
              </select>
            </div>
          )}

          {formData.thana === "শাহ-মখদুম" && (
            <div className="form-field">
              <label>এরিয়া*</label>
              <select
                name="area"
                required
                value={formData.value}
                onChange={handleInputChange}
              >
                <option value="">--এরিয়া সিলেক্ট করুন--</option>
                <option value="দরগাপাড়া">দরগাপাড়া</option>
                <option value="হেতেম খাঁ">হেতেম খাঁ</option>
                <option value="কাদিরগঞ্জ">কাদিরগঞ্জ</option>
                <option value="সাহেব বাজার">সাহেব বাজার</option>
                <option value="সুলতানাবাদ">সুলতানাবাদ</option>
                <option value="সপুরা">সপুরা</option>
                <option value="বর্ণালী">বর্ণালী</option>
              </select>
            </div>
          )}

          {formData.thana === "চন্দ্রিমা" && (
            <div className="form-field">
              <label>এরিয়া*</label>
              <select
                name="area"
                required
                value={formData.value}
                onChange={handleInputChange}
              >
                <option value="">--এরিয়া সিলেক্ট করুন--</option>
                <option value="শিরইল">শিরইল</option>
                <option value="ছোটবনগ্রাম">ছোটবনগ্রাম</option>
                <option value="শালবাগান">শালবাগান</option>
                <option value="নামো-ভদ্রা">নামো-ভদ্রা</option>
                <option value="পদ্মা আবাসিক">পদ্মা আবাসিক</option>
                <option value="সপুরা">সপুরা</option>
                <option value="চন্দ্রিমা আবাসিক">চন্দ্রিমা আবাসিক</option>
                <option value="মেহেরচন্ডী">মেহেরচন্ডী</option>
              </select>
            </div>
          )}

          <div className="form-field">
            <label>যেদিন সেবা নিতে চান*</label>
            <input
              type="date"
              name="date"
              min={today}
              value={formData.value}
              onChange={handleInputChange}
              required
            >
            </input>
          </div>

          <div className="form-field ">
            <label>বিস্তারিত*</label>
            <textarea
              name="details"
              value={formData.value}
              onChange={handleInputChange}
              required
              className="main-form-comment"
            ></textarea>
          </div>

          <div className="form-field">
            <button type="submit" on>জমা দিন</button>
          </div>
        </form>
        )}
      </div>
    </div>
  );
};

export default Mainform;
