import React, { useState } from "react";
import './account.css';


const ResetPass = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // Add your logic to send a password reset email here
    // This can be done using Firebase Authentication methods

    // For example using Firebase Auth
    // const auth = getAuth(firebaseapp);

    // sendPasswordResetEmail(auth, email)
    //   .then(() => {
    //     setSuccessMessage("Password reset email sent successfully");
    //   })
    //   .catch((error) => {
    //     setErrorMessage("Error sending password reset email: " + error.message);
    //   });
  };

  return (
    <div className="reset-pass-container">
      <h2 className="mb-4 text-2xl">Reset Password</h2>
      <form onSubmit={handleSubmit} className="reset-pass-form">
        <div className="mb-3">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
            placeholder="Enter your email"
            required
          />
        </div>
        <button
          type="submit"
          className="global-blue-bg px-3 py-2 rounded-md text-white"
        >
          Reset Password
        </button>
      </form>
      {successMessage && <p className="text-success">{successMessage}</p>}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
    </div>
  );
};

export default ResetPass;
