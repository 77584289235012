import React from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider,FacebookAuthProvider } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faFacebook } from "@fortawesome/free-brands-svg-icons";
import firebaseapp from "../../Firebase/Firebase.init";

const GoogleFBLogin = () => {

  const handleGoogleLogin = () => {
    const googleProvider = new GoogleAuthProvider();
    const auth = getAuth(firebaseapp);
    signInWithPopup(auth, googleProvider)
    .then ((result) => {
        const user = result.user;
        console.log(user);
    })
    .catch ((error) => {
        const errorCode = error.code;
        const errormessage = error.message;
        console.log(errorCode, errormessage);

    })
  };

  const handleFacebookLogin = () => {
    const facebookProvider = new FacebookAuthProvider();
    const auth = getAuth(firebaseapp);
    signInWithPopup(auth, facebookProvider)
    .then ((result) => {
        const user = result.user;
        console.log(user);
    })
    .catch ((error) => {
        const errorCode = error.code;
        const errormessage = error.message;
        console.log(errorCode, errormessage);

    })
  };

  return (
    <div className="google-fb-login">
      <button className="btn btn-danger mr-2" onClick={handleGoogleLogin}>
        <FontAwesomeIcon icon={faGoogle} className="mr-2" />
        Login with Google
      </button>
      <button className="btn btn-primary" onClick={handleFacebookLogin}>
        <FontAwesomeIcon icon={faFacebook} className="mr-2" />
        Login with Facebook
      </button>
    </div>
  );
};

export default GoogleFBLogin;
