// Register.jsx

import { React,  useContext,  useState } from 'react';
import { AuthContext } from '../../Utilities/AuthProvider';


const Register = () => {

  const { user, createUser} = useContext (AuthContext); 

  const [successmessage, setsuccessMessage] = useState('');
  const [errormessage, seterrorMessage] = useState('');

  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleSubmit = async (e) => {
    

    createUser(email,password)
    .then( result =>{
      const loggedUser = result.user;
      console.log(loggedUser);
      setsuccessMessage(`Welcome to Rajseba, ${displayName}`);
    })
    .catch(error =>{
      console.log(error);
      seterrorMessage("Something went wrong, Try Again. ", error.message);
    })
    
  };

  return (
    <div className="register-container">
      <h2 className='text-2xl text-bold text-blue-personal mb-4'>New to Rajseba? Register Now!</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Name"
          value={displayName}
          className="form-control"
          onChange={(e) => setDisplayName(e.target.value)}
          required
        /> <br />
        <input
          type="email"
          placeholder="Email"
          value={email}
          className="form-control"
          onChange={(e) => setEmail(e.target.value)}
          
        /> <br />
        <input
          type="password"
          placeholder="Password"
          value={password}
          className="form-control"
          onChange={(e) => setPassword(e.target.value)}
          required
        /> <br />
        <input
          type="text"
          placeholder="Phone Number"
          value={phoneNumber}
          className="form-control"
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
          pattern="[0]{1}[1]{1}[3-9]{1}[0-9]{8}"
        /> <br />
        <p className='text-blue-personal text-bold text-2xl'>{successmessage}</p>
        <p className='text-danger text-bold text-2xl'>{errormessage}</p>
        <button type="submit" className='global-blue-bg py-2 px-3 text-white rounded-md'>Register Now</button>
      </form>
    </div>
  );
};

export default Register;
