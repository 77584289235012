import React, { useContext } from 'react';
import { AuthContext } from '../../Utilities/AuthProvider';
import { Link } from 'react-router-dom';

const Profile = () => {

    const {user, logOut} = useContext(AuthContext);
    
    const {displayName, email, phoneNumber, photoURL} = user;
    
    const handleLogOut = () =>{
        logOut()
        .then(() => {})
        .catch ((error)=>{
            console.log(error.message);
        })

    }


    return (
        <div className='profile text-center mt-5'>
            <img src={photoURL} alt="" className='mx-auto rounded-md shadow-md p-2 global-blue-bg' />
            <h3>Name: {displayName}</h3>
            <h3>Email: {email}</h3>
            <h3>Phone Number: {phoneNumber}</h3>
            <h2>Total Services Taken: 50</h2>
            <h2>Orders: <Link to='/orders'>Click to See</Link></h2> <br />

            
            <button className='global-blue-bg px-3 py-2 text-white rounded-md mr-2' onClick={handleLogOut}>Log Out</button>
            <button className='global-blue-bg px-3 py-2 text-white rounded-md'>Edit Profile</button>
        </div>
    );
};

export default Profile;