import React from 'react';
import './homegallery.css';

const HomeGalleryImage = ({hgimage}) => {
    return (
        <div className='home-gallery-single-image'>
            <img src={hgimage.imagePath} alt="" className='rounded-md shadow-md'/>
        </div>
    );
};

export default HomeGalleryImage;