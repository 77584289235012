import React, { useContext } from 'react';
import { AuthContext } from '../../Utilities/AuthProvider';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({children}) => {
    const {user} = useContext(AuthContext);
    if(user){
        return children;
    }
    return (
        <div>
            <Navigate to="/account" replace = {true}></Navigate>
        </div>
    );
};

export default PrivateRoute;