import React from 'react';
import { Link } from 'react-router-dom';

const FormSuccess = () => {
    return (
        <div className='text-center'>
            <h3>আপনার ফর্মটি গ্রহণ করা হয়েছে। খুব শীঘ্রই রাজসেবার একজন প্রতিনিধি আপনাকে কল করবে।</h3>
            <h2>আমাদের আরো সার্ভিস দেখতে <Link to='services'>এখানে ক্লিক করুন।</Link> </h2>
        </div>
    );
};

export default FormSuccess;