// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBBiOnNnKtnvmCY7imy83Z75k6Y8hSiLqE",
  authDomain: "rajseba.firebaseapp.com",
  projectId: "rajseba",
  storageBucket: "rajseba.appspot.com",
  messagingSenderId: "8216042758",
  appId: "1:8216042758:web:d7c6760e868576d0c39287"
};

// Initialize Firebase
const firebaseapp = initializeApp(firebaseConfig);

export default firebaseapp;