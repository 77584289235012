import {React,useContext} from "react";

import GoogleFBLogin from "./GoogleFB";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Utilities/AuthProvider";

const Login = () => {
  const {signIn} = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailOrPhone = e.target.emailOrPhone.value;
    const password = e.target.password.value;

    try {
      signIn(
        emailOrPhone,
        password
      );
      
    } catch (error) {
      console.error("Error logging in:", error.message);
    }
  };

  return (
    <div className="login-container">
      <div className="email-pass-login">
        <form onSubmit={handleSubmit} className="login-form">
          <h2 className="mb-4 text-2xl text-bold text-blue-personal">Already Registered! Login here</h2>
          <div className="mb-3">
            <input
              type="text"
              name="emailOrPhone"
              className="form-control"
              placeholder="Email or Phone"
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="Password"
              required
            />
          </div> <br />

          <p className="mb-2">Forgot Password? Reset <Link to='/reset-password' className="text-blue-personal">Here</Link></p>

          <button
            type="submit"
            className="global-blue-bg px-3 py-2 rounded-md text-white"
          >
            Login
          </button>
        </form>
      </div>

      <div className="facebook-google-login">
        <h3 className="mx-auto my-2">You also can</h3>
        <GoogleFBLogin></GoogleFBLogin>
      </div>



    </div>
  );
};

export default Login;
