import React from 'react';
import Location from '../Location/Location'

const Contact = () => {
    return (
        <div>
            <Location></Location>
        </div>
    );
};

export default Contact;