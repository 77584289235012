import { React, useContext } from "react";
import Login from "./Login";
import "./account.css";
import Register from "./Register";
import Profile from "./Profile";
import { AuthContext } from "../../Utilities/AuthProvider";

const Account = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="account">
      {user ? (
        <div className="logged-in">
          <Profile></Profile>
        </div>
      ) : (
        <div className="not-logged-in md:flex mt-3">
          <div className="login w-50 p-3">
            <Login></Login>
          </div>
          <div className="register p-3">
            <Register></Register>
          </div>
        </div>
      )}
    </div>
  );
};

export default Account;
