import React from 'react';
import ServiceList from '../ServiceList/ServiceList';

const Services = () => {
    return (
        <div>
            <ServiceList></ServiceList>
        </div>
    );
};

export default Services;