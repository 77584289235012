import React from 'react';

const OurTeam = () => {
    return (
        <div>
            <h2>This is our team</h2>
            
        </div>
    );
};

export default OurTeam;