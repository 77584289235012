import React, { useContext } from "react";
import "./upperheader.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Utilities/AuthProvider";

const UpperHeader = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="bg-gray-800 py-3 text-blue-personal upperHeader-wrapper">
      <div className="hidden lg:flex lg:justify-between lg:items-center upperHeader">
        <div className="upperheader-location">
          <Link to="/location" target="_blank">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-white" />
            <span>অফিসের ঠিকানা</span>
          </Link>
        </div>

        <div className="book-now flex w-1/2 justify-end">
          <div className="book-online">
            <Link to="/booking">অনলাইনে বুক করুন</Link>
          </div>
          <div className="account flex justify-end">
            {user ? (
              <div className="logged-in flex items-center">
                  <img
                    src={user.photoURL}
                    alt="User"
                    className="user-avatar h-6 rounded-md mr-2"
                  />
                  <span className="user-name"><Link to='/account'>{user.displayName}</Link></span>
              </div>
            ) : (
              <div className="not-logged-in">
                <p>
                  <FontAwesomeIcon
                    icon={faUser}
                    className="mr-2"
                  ></FontAwesomeIcon>
                  <Link to="/account">Login now</Link>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpperHeader;
